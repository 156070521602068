import React from 'react'
import './index.css';
import circle from '../../images/circle.png'
import ellipse from '../../images/ellipse.png'
const Services = () => {
  return (
    <div>
      
      <img src={ellipse}  className='contain' height={"430"} width={"1440"}/>
      <div className='circle'>
      {/* Services */}
      </div>
      </div>
  )
}

export default Services;